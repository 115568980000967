import React from "react";
import { render } from "react-dom";
import { Router, browserHistory } from "react-router";
import routes from "./routes";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "./config";
import { UserProvider } from "@unifiedpatents/sso";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import { TopCompaniesProvider } from "./components/common/top-companies";
import "./app.css";

const Main = () => {
  const ToSHtmlLink = "<a href='//www.unifiedpatents.com/term-of-services' target='_new'>Terms of Use</a>";
  const PPHtmlLink = "<a href='//www.unifiedpatents.com/privacy-policy' target='_new'>Privacy Policy</a>";

  const userProviderProps = {
    clientId: AUTH0_CLIENT_ID,
    domain: AUTH0_DOMAIN,
    allowSignUp: true,
    languageDictionary: {
      title: "Portal - Unified Patents",
      signUpTerms: `I agree to the ${ToSHtmlLink} and ${PPHtmlLink}.`,
      socialLoginInstructions: `<small>By signing in via Google and LinkedIn, you agree to the ${ToSHtmlLink} and ${PPHtmlLink}.</small>`
    },
    provideUserStatus: metadata => {
      return {
        isAdmin: metadata.authorization.roles.indexOf("admin") >= 0,
        canAccessData: metadata.authorization.roles.indexOf("member-data") >= 0
      };
    },
    onAuthenticated: auth => {
      browserHistory.replace(auth.state);
    }
  };

  const theme = {
    components: {
      Menu: {
        itemBg: "transparent",
        lineWidth: 0
      }
    }
  }

  return (
    <ConfigProvider locale={enUS} theme={theme}>
      <UserProvider {...userProviderProps}>
        <TopCompaniesProvider>
          <Router history={browserHistory} routes={routes} />
        </TopCompaniesProvider>
      </UserProvider>
    </ConfigProvider>
  );
};

render(<Main />, document.getElementById("root"));
