import "whatwg-fetch";
import { API_URL } from "../config";

export async function getTopCompanies() {
  const response = await fetch(`${API_URL}/company/top`);
  if (!response.ok) throw new Error(response.statusText);
  const json = await response.json();
  return json && json.names ? json.names : [];
}

export async function getRelatedCases(name, withSubsidiaries = false) {
  const response = await fetch(`${API_URL}/company/${name}${withSubsidiaries ? "?subsidiaries=true" : ""}`);
  if (!response.ok) throw new Error(response.statusText);
  const json = await response.json();
  return json;
}
