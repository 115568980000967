import React, { Component } from "react";
import PropTypes from "prop-types";
import { getTopCompanies } from "../../services/api-company";
import { Link } from "react-router";
import { isArray } from "lodash";

const DEFAULT_COMPANIES = [
  "apple inc",
  "google llc",
  "samsung",
  "samsung electronics america, inc.",
  "samsung electronics co., ltd",
  "intel corporation",
  "microsoft corporation",
  "unified patents llc",
  "cisco systems, inc.",
  "lg electronics, inc.",
];

class TopCompaniesProvider extends Component {
  static childContextTypes = {
    companies: PropTypes.arrayOf(PropTypes.string)
  };

  state = {
    companies: DEFAULT_COMPANIES,
    loading: false,
  }

  getChildContext() {
    return {
      companies: this.state.companies
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    getTopCompanies()
      .then(companies => {
        if (this._isMounted) this.setState({
          companies,
          loading: false
        });
      })
      .catch(() => {
        if (this._isMounted) this.setState({
          companies: DEFAULT_COMPANIES,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return React.cloneElement(this.props.children, {
      key: this.state.companies.length // to force rerendering when result changes
    });
  }
}

const withTopCompanies = WrappedComponent => {
  const ComponentWithTopCompanies = (props, context) => {
    const companyRenderer = str => str && context.companies.includes(str.toLowerCase()) ? <Link to={`/company/${str}`}>{str}</Link> : str;
    const companiesRenderer = val => isArray(val) && val.length
      ? val.map((v, i) => (
        <p style={{ margin: 0 }} key={i}>
          {companyRenderer(v)}
        </p>
      ))
      : val;
    const newProps = {
      ...props,
      companies: context.companies,
      companyRenderer,
      companiesRenderer
    };
    return <WrappedComponent {...newProps} />;
  };
  ComponentWithTopCompanies.contextTypes = {
    companies: PropTypes.arrayOf(PropTypes.string),
    companyRenderer: PropTypes.func,
    companiesRenderer: PropTypes.func
  };

  return ComponentWithTopCompanies;
};

export { TopCompaniesProvider, withTopCompanies };
