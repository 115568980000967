import { API_URL } from "../config";
export const LANDSCAPE_DEFAULT_URL = `${API_URL}/patents/v6/_search`;
export const LANDSCAPE_DEFAULTS = {
  hevc: {
    version: "v4",
    versionsPublic: ["v4"],
    docsUrl: "https://support.unifiedpatents.com/hc/en-us/articles/360007517393-Video-Codec-Zone-Methodology",
    fields: {
      v4: "hevc_v5a_ft_c",
      trsent: "hevc_v5a_trsent_claim",
      trsentUS: "hevc_v5a_trsent_us_claim"
    },
    filters: {
      // v3: {
      //   count_by: "grant",
      //   pub_status: ["Active", "Unknown"],
      //   alpha_threshold: 60,
      //   pools: ["Training Set"]
      // },
      v4: {
        count_by: "grant",
        pub_status: ["Active", "Unknown"],
        alpha_threshold: 60,
        pools: ["Mpeg-La Hevc Patents", "Access Advance Hevc Patents"],
        venn_pools: ["Hevc Advance Members", "Mpeg-La Hevc Members", "Velos Media Members"],
        venn_pools_shorten: {
          "Velos Media Members": "Velos",
          "Hevc Advance Members": "Advance HEVC",
          "Mpeg-La Hevc Members": "MPEGLA HEVC"
        }
      },
      trsent: {
        count_by: "grant",
        pub_status: ["Active", "Unknown"],
        alpha_threshold: 60,
        pools: ["Mpeg-La Hevc Patents", "Access Advance Hevc Patents"],
        venn_pools: ["Hevc Advance Members", "Mpeg-La Hevc Members", "Velos Media Members"],
        venn_pools_shorten: {
          "Velos Media Members": "Velos",
          "Hevc Advance Members": "Advance HEVC",
          "Mpeg-La Hevc Members": "MPEGLA HEVC"
        }
      },
      trsentUS: {
        count_by: "grant",
        pub_status: ["Active", "Unknown"],
        alpha_threshold: 60,
        pools: ["Mpeg-La Hevc Patents", "Access Advance Hevc Patents"],
        venn_pools: ["Hevc Advance Members", "Mpeg-La Hevc Members", "Velos Media Members"],
        venn_pools_shorten: {
          "Velos Media Members": "Velos",
          "Hevc Advance Members": "Advance HEVC",
          "Mpeg-La Hevc Members": "MPEGLA HEVC"
        }
      }
    }
  },
  avc: {
    version: "v1",
    versionsPublic: ["v1"],
    docsUrl: "https://support.unifiedpatents.com/hc/en-us/articles/360007517393-Video-Codec-Zone-Methodology",
    fields: {
      v1: "avc_v1a_ft_c"
    },
    filters: {
      // v0: {
      //   count_by: "grant",
      //   pub_status: ["Active", "Unknown"],
      //   alpha_threshold: 60,
      //   pools: ["Mpeg-La Avc Patents"]
      // },
      v1: {
        count_by: "grant",
        pub_status: ["Active", "Unknown"],
        alpha_threshold: 60,
        pools: ["Mpeg-La Avc Patents"],
        venn_pools: ["Mpeg-La Avc Members", "Itu-T Avc Declarants"]
      }
    }
  },
  lte: {
    version: "v3",
    versionsPublic: ["v2", "v3"],
    docsUrl: "https://support.unifiedpatents.com/hc/en-us/articles/4447452742167",
    fields: {
      v2: "lte_v2c_ft",
      v3: "lte_v3a_ft_c"
    },
    filters: {
      v2: {
        count_by: "pub",
        alpha_threshold: 50,
        pub_status: ["Active", "Unknown"],
        pools: ["4g Declared"],
        venn_pools: ["5G Declared Families", "4G Declared Families", "3G Declared Families"]
      },
      v3: {
        count_by: "pub",
        alpha_threshold: 50,
        pub_status: ["Active", "Unknown"],
        pools: ["4g Declared"],
        venn_pools: ["5G Declared Families", "4G Declared Families", "3G Declared Families"]
      }
    }
  },
  "5g": {
    version: "v2",
    versionsPublic: ["v1"],
    docsUrl: "https://support.unifiedpatents.com/hc/en-us/articles/4447452742167",
    fields: {
      v1: "5g_v1c_ft",
      v2: "5g_v2a_ft_c"
    },
    filters: {
      v1: {
        count_by: "pub",
        pub_status: ["Active", "Unknown"],
        alpha_threshold: 50,
        pools: ["5g Declared"],
        venn_pools: ["5G Declared Families", "4G Declared Families", "3G Declared Families"]
      },
      v2: {
        count_by: "pub",
        pub_status: ["Active", "Unknown"],
        alpha_threshold: 50,
        pools: ["5g Declared"],
        venn_pools: ["5G Declared Families", "4G Declared Families", "3G Declared Families"]
      }
    }
  },
  lte_ran: {
    version: "v0",
    versionsPublic: ["v0"],
    docsUrl: "https://support.unifiedpatents.com/hc/en-us/articles/4415793634711",
    fields: {
      v0: "lteran_v0d_ft_c",
      v0_internal: "lteran_v0d_ft_c_internal"
    },
    filters: {
      v0: {
        count_by: "pub",
        alpha_threshold: 50,
        pools: ["4g Declared Families"],
        venn_pools: ["5G Declared Families", "4G Declared Families", "3G Declared Families"]
      },
      v0_internal: {
        count_by: "pub",
        alpha_threshold: 50,
        pools: ["4g Declared Families"],
        venn_pools: ["5G Declared Families", "4G Declared Families", "3G Declared Families"]
      }
    }
  },
  "5g_ran": {
    version: "v0",
    versionsPublic: ["v0"],
    docsUrl: "https://support.unifiedpatents.com/hc/en-us/articles/4415793634711",
    fields: {
      v0: "5gran_v1f_ft_c",
      v0_internal: "5gran_v1f_ft_c_internal"
    },
    filters: {
      v0: {
        count_by: "pub",
        alpha_threshold: 50,
        pools: ["5g Declared Families"],
        venn_pools: ["5G Declared Families", "4G Declared Families", "3G Declared Families"]
      },
      v0_internal: {
        count_by: "pub",
        alpha_threshold: 50,
        pools: ["5g Declared Families"],
        venn_pools: ["5G Declared Families", "4G Declared Families", "3G Declared Families"]
      }
    }
  },
  wifi6: {
    version: "v1",
    versionsPublic: ["v0", "v1"],
    docsUrl: "https://support.unifiedpatents.com/hc/en-us/articles/7250711661847",
    fields: {
      v0: "wifi6_v0a_ft_c",
      v1: "wifi6_v1a_ft_c"
    },
    filters: {
      v0: {
        count_by: "pub",
        alpha_threshold: 50,
        pools: ["Sisvel Wifi 6 Members"],
        venn_pools: ["Ieee Wifi 6 Contributors", "Sisvel Wifi 6 Members"]
      },
      v1: {
        count_by: "pub",
        alpha_threshold: 64,
        pools: ["Sisvel Wifi 6 Members"],
        venn_pools: ["Ieee Wifi 6 Contributors", "Sisvel Wifi 6 Members"]
      }
    }
  },
  vvc: {
    version: "v1",
    versionsPublic: ["v1"],
    docsUrl: "https://support.unifiedpatents.com/hc/en-us/articles/12900363504535",
    fields: {
      v0: "vvc_v0a_ft_c",
      v1: "vvc_v1a_ft_c"
    },
    filters: {
      v0: {
        count_by: "pub",
        alpha_threshold: 50,
        pools: ["Access Advance Vvc Patents", "Mpeg-La Vvc Patents"],
        venn_pools: ["Access Advance Vvc Patents", "Mpeg-La Vvc Patents"]
      },
      v1: {
        count_by: "pub",
        alpha_threshold: 72,
        pools: ["Access Advance Vvc Patents", "Mpeg-La Vvc Patents"],
        venn_pools: ["Access Advance Vvc Patents", "Mpeg-La Vvc Patents"]
      }
    }
  }
};
